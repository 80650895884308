<template>
  <div>
    <custom-toolbar
      :title="$t('Roads')"
      :total="$store.getters['road/total']"
      @refresh="getRoads"
    />
    <v-card class="mb-2 mt-2">
      <v-alert
        v-model="isAlertVisible"
        :color="error ? 'error' : 'success'"
        dismissible
        text
        transition="slide-y-transition"
      >
        {{ message }}
      </v-alert>
      <v-card-title
        class="ml-4"
        style="padding-top: 2px; padding-bottom: 2px"
      >
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-row class="marginHeader">
            <v-col
              cols="12"
              md="4"
              sm="4"
              xs="4"
              class="marginCity"
            >
              <city-input
                v-model="$store.state.road.field.city"
                :label="'Rechercher par la ville de départ'"
                :placeholder="'Rechercher par la ville de départ'"
                :rules="rules.city"
                @change="filterItems"
                @getCity="getStartOmsId"
              ></city-input>
            </v-col>
            <v-col
              cols="12"
              md="4"
              sm="4"
              xs="4"
              class="marginCity"
            >
              <city-input
                v-model="$store.state.road.field.destination"
                :label="'Rechercher par la destination'"
                :placeholder="'Rechercher par la destination'"
                :rules="rules.destination"
                @change="filterItems"
                @getCity="getEndOmsId"
              ></city-input>
            </v-col>
            <v-col
              cols="12"
              md="4"
              sm="4"
              xs="4"
            >
              <v-text-field
                v-model="$store.state.road.field.car"
                label="Rechercher par voiture"
                outlined
                dense
                placeholder="Rechercher par voiture"
                hide-details="auto"
                :rules="rules.car"
                clearable
                @input="filterItems"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="marginHeader">
            <v-col
              cols="12"
              md="4"
              sm="4"
              xs="4"
            >
              <v-text-field
                v-model="$store.state.road.field.min_amount"
                label="Filtrer par montant minimum"
                outlined
                dense
                placeholder="Filtrer par montant minimum"
                hide-details="auto"
                :rules="rules.min_amount"
                clearable
                @input="filterItems"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="4"
              sm="4"
              xs="4"
            >
              <v-text-field
                v-model="$store.state.road.field.max_amount"
                label="Filtrer par montant maximum"
                outlined
                dense
                placeholder="Filtrer par montant maximum"
                hide-details="auto"
                :rules="rules.max_amount"
                clearable
                @input="filterItems"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="4"
              sm="4"
              xs="4"
            >
              <v-menu
                ref="menuref"
                v-model="menu"
                :close-on-content-click="true"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="$store.state.road.field.start_date"
                    label="Filtrer par date de départ"
                    outlined
                    dense
                    hide-details="auto"
                    v-bind="attrs"
                    :rules="rules.start_date"
                    clearable
                    v-on="on"
                    @input="getRoadsByFields"
                  ></v-text-field>
                </template>
                <v-date-picker
                  ref="picker"
                  v-model="$store.state.road.field.start_date"
                  min="1950-01-01"
                  color="primary"
                  @change="save"
                  @input="getRoadsByFields"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
    <v-card>
      <v-data-table
        :loading="isDialogVisible"
        loading-text="Chargement..."
        :headers="headers"
        :items="$store.getters['road/roads']"
        :items-per-page="$store.getters['road/per_page']"
        :page.sync="$store.getters['road/current_page']"
        dense
        fixed-header
        height="500"
        hide-default-footer
        @page-count="currentPage = $event"
      >
        <template #[`item.start_date`]="{item}">
          {{ item.start_date !== null ? formattingStartDate(`${item.start_date}`) : null }}
        </template>
        <template #[`item.user`]="{item}">
          {{ item.user !== null ? (item.user.name !== null ? item.user.name : item.user.phone_number) : null }}
        </template>
        <template #[`item.nb_passagers_left`]="{item}">
          <v-chip
            :color="item.nb_passagers_left > 0 ? 'success' : 'error'"
            small
          >
            {{ item.nb_passagers_left + '/' + item.nb_passagers }}
          </v-chip>
        </template>
        <template #[`item.global_amount`]="{item}">
          <v-chip
            :color="new Date(item.start_date) > new Date() ? 'primary' : 'error'"
            small
          >
            {{ formatAmount(item.global_amount) }}
          </v-chip>
        </template>

        <template #[`item.created_at`]="{item}">
          {{ formattingDate(`${item.created_at}`) }}
        </template>

        <template #[`item.cancelled_at`]="{item}">
          <v-chip
            v-show="item.cancelled_at !== null"
            color="error"
            small
          >
            <span class="font-weight-bold">{{ item.cancelled_at ? formattingDate(`${item.cancelled_at}`) : null }}</span>
          </v-chip>
        </template>

        <template #[`item.car`]="{item}">
          {{ item.car !== null ? `${item.car.marque} ${item.car.model} ${item.car.release_year}` : null }}
        </template>

        <!-- action -->
        <template #[`item.actions`]="{ item }">
          <buttons-group
            :item="item"
            :lock="false"
            :remove="false"
            :edit="false"
            :status="false"
            @view="openItem(item)"
          />
        </template>
      </v-data-table>

      <v-card-text class="pt-2">
        <v-row>
          <v-col
            lg="2"
            cols="3"
          >
            <v-text-field
              :value="$store.state.road.field.paginate"
              label="Lignes par page:"
              type="number"
              min="-1"
              max="15"
              hide-details
              @input="getRoadsPerPage"
            ></v-text-field>
          </v-col>

          <v-col
            lg="10"
            cols="9"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="currentPage"
              total-visible="6"
              :length="lastPage"
              @input="getRoads"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="dialogDelete"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          Voulez-vous bloquer cet utilisateur?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            @click="closeDelete"
          >
            Non
          </v-btn>
          <v-btn
            color="success"
            @click="deleteItemConfirm"
          >
            Oui
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  mdiSquareEditOutline, mdiDotsVertical, mdiDeleteOutline, mdiPencilOutline, mdiAccountLock, mdiLockOpen, mdiEyeOutline, mdiRefresh,
} from '@mdi/js'
import { mapGetters, mapState } from 'vuex'
import LoaderDialog from '@core/components/dialogs/LoaderDialog'
import { ref, getCurrentInstance, onMounted } from '@vue/composition-api/dist/vue-composition-api'
import CityInput from '@core/components/autocomplete/CityInput'
import { formatDate, formatStartDate } from '@core/date/dateFunctions'
import {intToCurrency, notify} from '@core/dynamicComponents/dynamicComponents'
import { useRouter } from '@core/utils'
import CustomToolbar from '@core/components/toolbar/CustomToolbar'
import ButtonsGroup from '@core/components/buttons/ButtonsGroup'
import store from '@/store'
import { i18n } from '@/plugins/i18n'
import router from '@/router'

export default {
  name: 'Road',
  components: {
    ButtonsGroup, CustomToolbar, CityInput, LoaderDialog,
  },

  setup(props, context) {
    const instance = getCurrentInstance()
    const isLockedColor = {
      false: 'info',
      true: 'success',
    }
    const date = ref(null)
    const menu = ref(false)
    const picker = ref(null)
    const menuref = ref(null)
    const currentPage = ref(null)
    const lastPage = ref(null)
    const dialogDelete = ref(false)
    const isDialogVisible = ref(false)
    const message = ref(null)
    const error = ref(false)
    const isAlertVisible = ref(false)
    const rules = {
      max_amount: [true],
      min_amount: [true],
      nb_passagers: [true],
      start_date: [true],
      city: [true],
      destination: [true],
      car: [true],
    }
    const { router } = useRouter()

    const save = d => {
      menuref.value.save(d)
    }

    const formattingDate = date => formatDate(date)

    const formattingStartDate = date => formatStartDate(date)

    const deleteItemConfirm = () => {
      closeDelete()
    }

    const openItem = item => {
      router.push({ name: 'showroad', params: { id: item.id } })
    }

    const closeDelete = () => {
      dialogDelete.value = false
    }

    const getRoads = (page = 1) => {
      isDialogVisible.value = true
      store.dispatch('road/getRoadsList', { page, field: store.state.road.field }).then(() => {
        currentPage.value = store.getters['road/current_page']
        lastPage.value = store.getters['road/last_page']
        isDialogVisible.value = false
        isAlertVisible.value = false

        /* Object.keys(this.rules).forEach(key => {
          this.rules[key] = [true]
        }) */
      }).catch(errorserver => {
        message.value = errorserver.response.data.message
        if (errorserver.response.data.errors !== undefined) {
          rules.max_amount = errorserver.response.data.errors.max_amount !== undefined ? errorserver.response.data.errors.max_amount : [true]
          rules.min_amount = errorserver.response.data.errors.min_amount !== undefined ? errorserver.response.data.errors.min_amount : [true]
          rules.nb_passagers = errorserver.response.data.errors.nb_passagers !== undefined ? errorserver.response.data.errors.nb_passagers : [true]
          rules.start_date = errorserver.response.data.errors.start_date !== undefined ? errorserver.response.data.errors.start_date : [true]
          rules.car = errorserver.response.data.errors.car !== undefined ? errorserver.response.data.errors.car : true
          rules.city = errorserver.response.data.errors.city !== undefined ? errorserver.response.data.errors.city : true
          rules.destination = errorserver.response.data.errors.destination !== undefined ? errorserver.response.data.errors.destination : [true]
        }
        error.value = true
        isAlertVisible.value = true
      })
    }

    const getStartOmsId = event => {
      store.state.road.field.city = event ? event.toString() : null
      getRoadsByFields()
    }

    const getEndOmsId = event => {
      store.state.road.field.destination = event ? event.toString() : null
      getRoadsByFields()
    }

    const getRoadsByFields = () => {
      isDialogVisible.value = true
      store.dispatch('road/getRoadsList', {
        page: 1,
        field: store.state.road.field,
      }).then(() => {
        currentPage.value = store.getters['road/current_page']
        lastPage.value = store.getters['road/last_page']
        isDialogVisible.value = false
        isAlertVisible.value = false
        error.value = false
        isAlertVisible.value = false

        /* Object.keys(this.rules).forEach(key => {
          this.rules[key] = [true]
        }) */
      }).catch(error => {
        message.value = error.response.data.message
        if (error.response.data.errors !== undefined) {
          rules.max_amount = error.response.data.errors.max_amount !== undefined ? error.response.data.errors.max_amount : [true]
          rules.min_amount = error.response.data.errors.min_amount !== undefined ? error.response.data.errors.min_amount : [true]
          rules.nb_passagers = error.response.data.errors.nb_passagers !== undefined ? error.response.data.errors.nb_passagers : [true]
          rules.start_date = error.response.data.errors.start_date !== undefined ? error.response.data.errors.start_date : [true]
          rules.car = error.response.data.errors.car !== undefined ? error.response.data.errors.car : [true]
          rules.city = error.response.data.errors.city !== undefined ? error.response.data.errors.city : [true]
          rules.destination = error.response.data.errors.destination !== undefined ? error.response.data.errors.destination : [true]
        }
        error.value = true
        isAlertVisible.value = true
      })
    }

    const getRoadsPerPage = per_page => {
      // eslint-disable-next-line camelcase
      if (per_page !== null) {
        isDialogVisible.value = true
        // eslint-disable-next-line camelcase
        store.state.road.field.paginate = per_page

        // this.itemsPerPage = parseInt(per_page, 10)
        store.dispatch('road/getRoadsList', {
          page: currentPage.value,
          field: store.state.road.field,
        }).then(() => {
          currentPage.value = store.getters['road/current_page']
          lastPage.value = store.getters['road/last_page']
          isDialogVisible.value = false
          isAlertVisible.value = false

          /* Object.keys(this.rules).forEach(key => {
            this.rules[key] = [true]
          }) */
        })
      }
    }

    const filterItems = () => {
      setTimeout(() => {
        getRoadsByFields()
      }, 1500)
    }

    onMounted(() => {
      getRoads(store.getters['road/current_page'])
    })

    const formatAmount = amount => intToCurrency(store.state.user.lang, {
      style: 'currency',
      currency: 'XAF',
      minimumFractionDigits: 0,
    }, amount)

    return {
      formatAmount,
      getRoadsPerPage,
      filterItems,
      getRoadsByFields,
      getStartOmsId,
      getEndOmsId,
      openItem,
      closeDelete,
      formattingDate,
      formattingStartDate,
      deleteItemConfirm,
      getRoads,
      currentPage,
      lastPage,
      dialogDelete,
      isDialogVisible,
      message,
      isAlertVisible,
      error,
      rules,
      selectOptions: [
        { text: 'Oui', value: true },
        { text: 'Non', value: false },
        { text: 'Tous', value: null },
      ],
      save,
      date,
      menu,
      picker,
      menuref,
      headers: [
        { text: 'DEPART', value: 'city.name' },
        { text: 'DESTINATION', value: 'destination.name' },
        { text: 'PRIX', value: 'global_amount' },
        { text: 'CHAUFFEUR', value: 'user' },
        { text: 'VOITURE', value: 'car.marque' },
        { text: 'PLACES RESTANTES', value: 'nb_passagers_left' },
        { text: 'HEURE', value: 'start_date' },
        { text: 'DATE DE CREATION', value: 'created_at' },
        { text: 'ANNULE LE', value: 'cancelled_at' },
        { text: 'ACTIONS', value: 'actions' },
      ],
      //itemsPerPage: parseInt(store.state.road.field.paginate, 10),
      status: {
        1: 'Current',
        2: 'Professional',
        3: 'Rejected',
        4: 'Resigned',
        5: 'Applied',
      },
      is_locked: {
        1: true,
        2: false,
      },
      isLockedColor,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiAccountLock,
        mdiLockOpen,
        mdiEyeOutline,
        mdiRefresh,
      },
    }
  },
}
</script>

<style scoped>
@media only screen and (max-width: 1904px) {
  .marginHeader {
    margin: -26px;
  }
}

@media only screen and (max-width: 600px) {
  .marginHeader {
    margin: -13px;
  }
}

@media only screen and (max-width: 600px) {
  .marginCity {
    margin-bottom: -30px;
  }
}

</style>
